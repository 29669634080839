@import "src/themes/mojito/styles/index.scss";
.circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 6px,
    rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
