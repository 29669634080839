@import "src/themes/mojito/styles/index.scss";
.color-group {
  display: flex;
  flex-direction: column;
  gap: 0 !important;

  @include sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: space(2) !important;
  }

  @include md {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: space(1) !important;
  }

  .Input {
    width: 100%;

    input {
      width: 100%;
      .picker {
        width: auto !important;

        .swatch {
          width: 2rem !important;
        }
      }
    }
  }

  button {
    margin: 0 0 1rem !important;
    @include sm {
      margin: 0 0 space(0.5) !important;
    }

    @include md {
      margin: auto 2rem !important;
    }
  }
}
