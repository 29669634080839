@import "src/themes/mojito/styles/index.scss";
.timer {
  max-width: 17rem;
  margin: 0 auto;

  &__analogical {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
  }

  &__label {
    font-size: 1rem;
    font-weight: 700;
    color: getvar($colors, "neutral", "500");
    text-align: left;
  }

  &__view {
    display: flex;
    margin-bottom: space(2.5);
    justify-content: space-between;

    &__container {
      display: flex;
    }

    &__time {
      display: flex;
      align-items: center;

      &__hour {
        font-size: 3.125rem !important;
        margin-bottom: 0 !important;
        color: getvar($colors, "neutral", "500");

        &--selected {
          color: getvar($colors, "neutral", "800");
        }
      }

      &__minutes {
        font-size: 3.125rem !important;
        margin-bottom: 0 !important;
        color: getvar($colors, "neutral", "500");

        &--selected {
          color: getvar($colors, "neutral", "800");
        }
      }

      &__separator {
        font-size: 3.125rem;
        margin-bottom: 0 !important;
        color: getvar($colors, "neutral", "500");
      }

      &__edit-clock {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &__button {
        &-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-left: 1rem;
          min-width: 2rem;

          span {
            font-size: 1rem;
            font-weight: 700;
            color: getvar($colors, "neutral", "500");
          }
        }

        &-am {
          &--selected {
            color: getvar($colors, "neutral", "800") !important;
          }
        }

        &-pm {
          &--selected {
            color: getvar($colors, "neutral", "800") !important;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;

    button {
      display: flex;
      justify-content: center;
      width: 104px !important;
      min-width: unset;
    }
  }
}
