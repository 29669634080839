@import "src/themes/mojito/styles/index.scss";
.opening-hours {
  &__format {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: getvar($colors, "primary", "50");
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    gap: 5px;

    @include md {
      justify-content: flex-start;
    }

    button {
      min-width: unset;
      width: 155px !important;
      margin: 0 !important;
      background-color: white !important;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem !important;

      @include md {
        margin-left: 2rem !important;
      }
    }
  }

  &__days {
    &__divider {
      margin-top: 1rem !important;
    }

    &__day {
      @include md {
        display: flex;
        align-items: baseline;
      }

      .Checkbox--opening-hours__days__day__checkbox {
        @include md {
          width: 20%;
        }
      }

      &__times {
        @include md {
          width: 80%;
        }

        &__time:last-child {
          margin-bottom: 0 !important;
        }

        &__time {
          display: flex;
          margin-top: 1rem;
          margin-bottom: 1rem;

          @include md {
            margin-top: 0 !important;
          }

          div,
          button {
            margin: 0 !important;
          }

          button {
            min-width: 50px;
            margin-left: 0.5rem !important;
          }
        }
      }
    }
  }
}

.button--add-remove--disabled {
  pointer-events: none;
  border: 1px solid getvar($colors, "primary", "300") !important;

  svg {
    color: getvar($colors, "primary", "300") !important;
  }
}

.button--hours__format--disabled {
  opacity: 0.5;
}

.Business {
  &__input {
    position: relative;
    width: 50%;

    &__default {
      color: getvar($colors, "neutral", "800 ");
    }

    &__placeholder {
      color: getvar($colors, "neutral", "300 ");
    }

    &--disabled {
      pointer-events: none;

      svg {
        color: getvar($colors, "neutral", "300");
      }

      div {
        color: getvar($colors, "neutral", "300");
        border: 1px solid getvar($colors, "neutral", "300") !important;
        background-color: white;
      }
    }

    svg {
      pointer-events: none;
      height: space(1.25);
      width: space(1.25);
      color: getvar($colors, "neutral", "700");
      position: absolute;
      right: 12px;
      top: 15px;
    }

    div {
      appearance: none;
      cursor: pointer;
      min-height: 1rem;
      padding: space(0.75) space(0.75) space(0.75) space(0.75);
      background-color: white;
      border-radius: 0.25em;
      border: 1px solid getvar($colors, "neutral", "500");
      font-size: 14px;
      outline: 0;
      margin-right: 0.5rem !important;
      width: auto !important;

      &:hover {
        border: 1px solid getvar($colors, "neutral", "500");
      }

      &:focus {
        outline: none;
        border: 1px solid getvar($colors, "primary", "500");
      }

      &::-ms-expand {
        display: none;
      }

      &:invalid {
        color: getvar($colors, "neutral", "500");
      }
    }
  }
}
